import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { Device } from '@capacitor/device';

import { useApi } from 'composables/api/useApi';
import { useAuth } from 'composables/useAuth';

export const DEVICE_ID_KEY = 'DEVICE_ID_KEY';
export const DEVICE_TOKEN_KEY = 'DEVICE_TOKEN_KEY';

export const useGadgetsStore = defineStore('gadgets', () => {
  const { request } = useApi();
  const { user } = useAuth();
  // Removing 'auth0|' part of userId
  const userId = user?.value?.sub?.split('|')[1];

  // State
  const notificationTypes = ref<any>([]);
  const preferences = ref<any[]>([]);

  // Getters
  const getNotificationTypes = computed(() => notificationTypes.value);

  // Actions
  const fetchCurrentGadget = async () => {
    const gadgetId = localStorage.getItem(DEVICE_ID_KEY);
    if (!userId || !gadgetId) {
      console.error(
        'Error in fetchCurrentGadget: userId or gadgetId is undefined',
      );
      return;
    }

    const url = `/users/${userId}/gadgets/${gadgetId}`;
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => response);
  };

  const fetchGadget = async (gadgetId: string) => {
    if (!userId || !gadgetId) {
      console.error('Error in fetchGadget: userId or gadgetId is undefined');
      return;
    }

    const url = `/users/${userId}/gadgets/${gadgetId}`;
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => response);
  };

  const fetchGadgets = async () => {
    if (!userId) {
      console.error('Error in fetchGadgets: userId is undefined');
      return;
    }

    const url = `/users/${userId}/gadgets`;
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => response);
  };

  const fetchNotificationTypes = async () => {
    const url = '/notification_types';
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => {
      if (!response) return;

      notificationTypes.value = response?.data?.value;
      return response;
    });
  };

  const fetchNotificationPreferences = async () => {
    if (!userId) {
      console.error(
        'Error in fetchNotificationPreferences: userId is undefined',
      );
      return;
    }

    const url = `/users/${userId}/gadgets`;
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => {
      preferences.value = response?.data?.value[0]?.notification_types;

      return response?.data?.value[0]?.notification_types;
    });
  };

  // Generate a unique gadget (device) ID
  const generateGadgetId = () => {
    const timestamp = new Date().getTime();
    const randomPart = Math.random().toString(36).substring(2, 9);

    return `gadget_${randomPart}_${timestamp}`;
  };

  const registerGadget = async (deviceToken: string) => {
    if (!userId || !deviceToken) {
      console.error(
        'Error in registerGadget: userId or deviceToken is undefined',
      );
      return;
    }

    const gadgetId = generateGadgetId();
    const deviceInfo = await Device.getInfo();

    const url = `/users/${userId}/gadgets`;
    const data = {
      gadget_id: gadgetId,
      gadget_model: deviceInfo.model,
      gadget_token: deviceToken,
    };
    const config = request({
      url,
      method: 'PUT',
      data,
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => {
      localStorage.setItem(DEVICE_ID_KEY, gadgetId);
      localStorage.setItem(DEVICE_TOKEN_KEY, deviceToken);

      return response;
    });
  };

  const unregisterGadget = async (fallbackGadgetId: string) => {
    const gadgetId = localStorage.getItem(DEVICE_ID_KEY);
    if (!gadgetId || !fallbackGadgetId) {
      console.error(
        'Error in unregisterGadget: gadgetId or fallbackGadgetId is undefined',
      );
      return;
    }
    if (!userId) return;
    try {
      const url = `/users/${userId}/gadgets/${gadgetId || fallbackGadgetId}/token`;
      const config = request({
        url,
        method: 'DELETE',
        instanceId: 'cloud',
      });

      return await config.execute().then((response) => {
        return response;
      });
    } catch (e) {
      console.error('unregisterGadget - ', e);
    }
  };

  const updateNotificationPreference = async ({ data }: any) => {
    const gadgetId = localStorage.getItem(DEVICE_ID_KEY);
    if (!userId || !gadgetId) {
      console.error(
        'Error in updateNotificationPreference: userId or gadgetId is undefined',
      );
      return;
    }

    const url = `/users/${userId}/gadgets/${gadgetId}/notification_preferences`;
    const config = request({
      url,
      method: 'PUT',
      data,
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => response);
  };

  return {
    // Getters
    getNotificationTypes,
    // Actions
    fetchCurrentGadget,
    fetchGadget,
    fetchGadgets,
    fetchNotificationPreferences,
    fetchNotificationTypes,
    generateGadgetId,
    registerGadget,
    unregisterGadget,
    updateNotificationPreference,
    $reset: () => {
      preferences.value = [];
      notificationTypes.value = [];
    },
  };
});

import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDevicesStore } from '../devices';
import { useSystemsStore } from '../systems';
import handleEvent from './handleEvent';
import { getCurrentInstance } from 'vue';
import { useSocket, buildWsUrl } from 'src/composables/api/useSocket';
import { useNativeSocket } from 'src/composables/api/useNativeSocket';
import { DeviceAttribute, DeviceAttributes } from 'src/types/DeviceAttributes';
import { System } from 'src/types/System';
import { until } from '@vueuse/core';
import { useLocalAuth } from 'src/composables/useLocalAuth';

export const useSocketStore = defineStore('sockets', () => {
  const systemsStore = useSystemsStore();
  const devicesStore = useDevicesStore();
  const instance = getCurrentInstance();
  const localAuth = useLocalAuth();

  // create a web sockets instance
  const socket = useSocket();
  const nativeSocket = useNativeSocket();
  const connecting = ref(false);
  function $reset() {
    // Not really anything to reset here but we still need the function
  }
  const selectedSystemId = computed(() => systemsStore.getSelectedSystemId);
  const hasSystemChanged = ref(true);
  watch(selectedSystemId, (newValue, oldValue) => {
    if (newValue != oldValue) {
      hasSystemChanged.value = true;
    } else {
      hasSystemChanged.value = false;
    }
  });

  // Watch for streams data to change.
  // Note: this is equivalent to the ws.onMessage callback function but "vue-ie"
  watch(
    () => nativeSocket.stream.value?.data,
    (data) => {
      if (data) processData(String(data));
    },
  );

  watch(
    () => socket.stream.value?.data,
    (data) => {
      if (data) processData(String(data));
    },
  );

  function processData(message: string) {
    if (!message) return;
    const event = JSON.parse(message);
    // const message = JSON.parse(data as any);
    if (event.type === 'readings') {
      const deviceAttributes: DeviceAttribute[] = event.data.attributes;
      const attributes: Record<string, DeviceAttribute> = {};
      // Note: convert the reading events from an array to an object.
      deviceAttributes.forEach((attribute) => {
        const { name } = attribute;
        if (name) attributes[name] = attribute;
      });
      // Update the local device model
      devicesStore.updateDeviceAttributes(
        attributes as DeviceAttributes,
        event.data.device_id,
      );
    } else if (event.type === 'events') {
      handleEvent(event.data);
    } else {
      console.warn('[useSocketStore] Unhandled web socket message', event);
    }
  }

  async function connectToSocket(systemId: System['id']) {
    if (hasSystemChanged.value) await disconnect();

    // If we are already connected lets not try to reconnect unless its to a different system
    const hubService = systemsStore.getSystemsHubService;
    const isConnectedToCloudAndLocalUnavailable =
      socket.connected.value && !hubService;
    const isLocalConnected = nativeSocket.connected.value;
    if (isConnectedToCloudAndLocalUnavailable || isLocalConnected) {
      console.info(
        '[useSocketStore]: Canceling redundant socket connect attempt',
      );
      return;
    }
    connecting.value = true;
    // NOTE: if you call getCurrentInstance within the scope/context of a setup script this func should not be null
    if (instance) {
      console.info('[useSocketStore]: Connect to socket');

      if (systemId?.length === 0) {
        throw new Error('[useSocketStore]: connectToSocket - no system id set');
      }
      const path = `systems/${systemId}/streaming`;
      try {
        const baseUrl = await buildWsUrl(instance, path);
        if (baseUrl.isLocal) {
          nativeSocket.url.value = baseUrl.wsUrl;
          const headers: {
            'X-Local-Token-ID'?: string;
            'X-Local-Token-Key'?: string;
          } = {};
          if (hubService) {
            const localAuthToken =
              localAuth.localAuthTokens.value[hubService.txtRecord.hub_id];
            // If a local auth token exists add it to the headers for this hub
            if (localAuthToken) {
              headers['X-Local-Token-ID'] = localAuthToken.id;
              headers['X-Local-Token-Key'] = localAuthToken.secret;
            }
          }

          nativeSocket.connect(headers);
        } else {
          socket.url.value = baseUrl.wsUrl;
          if (socket.connected.value === false) socket.connect();
        }
      } catch (e) {
        console.error(e);
      }
    }
    connecting.value = false;
  }
  async function disconnect() {
    if (nativeSocket.connected.value) {
      console.info('[useSocketStore]: Disconnecting from native ws');
      try {
        nativeSocket.disconnect();
        await until(nativeSocket.connected).toBe(false, {
          timeout: 5000,
          throwOnTimeout: true,
        });
      } catch (e) {
        console.error('useSocketStore - native socket disconnect error', e);
      }
    }
    if (socket.connected.value) {
      console.info('[useSocketStore]: Disconnecting from cloud ws');
      try {
        socket.disconnect();
        await until(socket.connected).toBe(false, {
          timeout: 5000,
          throwOnTimeout: true,
        });
      } catch (e) {
        console.error('useSocketStore - socket disconnect error', e);
      }
    }
  }
  return { connectToSocket, disconnect, $reset };
});

import { ComputedRef, Ref, computed, onMounted, ref, watch } from 'vue';
import {
  ZeroConf,
  ZeroConfWatchRequest,
  ZeroConfService,
  ZeroConfWatchAction,
} from '@luminsmart/capacitor-zeroconf';
import { Platform } from 'quasar';
import { useSettings } from './useSettings';
import { useSystemsStore } from '../stores/systems';
import { useHubStore } from 'src/stores/useHubStore';

// register custom plugin
import { Capacitor, registerPlugin } from '@capacitor/core';
import { App } from '@capacitor/app';
import { useEnvironments } from './api/useEnvironments';
import { useNetwork } from './useNetwork';
import { useAuth } from '@luminsmart/building-blocks';
import { useStorage } from '@vueuse/core';
export interface CustomTLSValidator {
  validateTLS(options: object): Promise<void>;
}

export interface WSHostnameUpdater {
  updateHostNames(options: object): Promise<void>;
}

interface ServiceEntry {
  name: string;
  ipv4Address: string;
}

export interface ZeroConfType {
  services: Ref<Record<string, ZeroConfService>>;
  servicesArray: ComputedRef<ZeroConfService[]>;
  getServiceBySystemId: (systemId: string) => ZeroConfService | undefined;
}

const CustomTLSValidator =
  registerPlugin<CustomTLSValidator>('CustomTLSValidator');

const CapacitorWebsocket =
  registerPlugin<WSHostnameUpdater>('CapacitorWebsocket');

const SUCCESS_STATUSES = {
  RESOLVED: 'resolved',
  ADDED: 'added',
  REMOVED: 'removed',
  SETUP: 'setup',
  READY: 'ready', // the ready state gets fired on first run no mater what... might cause issues
};

const WAIT_STATUSES = {
  LOADING: 'loading',
};

const FAULT_STATUES = {
  POLICY_DENIED: 'waiting -65570: PolicyDenied', // IOS - only
  NO_AUTH: 'failed -65555: NoAuth', // IOS - Only
  RESUME_FAILED: 'failed -65569: DefunctConnection', // IOS - Only: This happens when the user leaves the app and the zeroconf water dies
  CANCELED: 'cancelled', // IOS - example: app had permission then the permission was revoked
  ERROR: 'error', // IOS - only
  UNKNOWN: 'unknown', // IOS - only
};

// when the app hasn't called the startDiscovery function yet.
const APP_UNKNOWN = 'app-unknown';
const ZEROCONF_STATUSES = {
  ...SUCCESS_STATUSES,
  ...WAIT_STATUSES,
  ...FAULT_STATUES,
};

const cachedServices = useStorage<Record<string, ZeroConfService>>('mdns', {});
// services are network connected devices advertising their information
const services = ref<Record<string, ZeroConfService>>({});
// TODO: refactor the exported services object into an array of objects and update codebase
// Using this as a computed will maintain reactivity.
const servicesArray = computed(() => {
  return Object.values(services.value);
});
const statusCache = ref<Array<ZeroConfWatchAction>>([]);

export function useZeroConf() {
  const settings = useSettings();
  const systemStore = useSystemsStore();
  const auth = useAuth();
  const network = useNetwork();

  const getZeroConfStatus = computed(() => {
    let status = APP_UNKNOWN; // the app doesnt know anything about the state of the plugin
    if (statusCache.value && statusCache.value.length) {
      const tempStatus = statusCache.value.find(
        (s: string, i: number) => i === statusCache.value.length - 1,
      );
      if (tempStatus) status = tempStatus;
    }
    return status;
  });

  const checkPermissions = computed(() => {
    let status = 'denied';
    if (Platform.is.ios) {
      const isPermissionGranted = checkIfGranted();
      const isPermissionDenied = checkIfDenied();
      const isPermissionLoading = checkIfLoading();

      if (isPermissionLoading) {
        status = 'loading';
      } else if (isPermissionGranted) {
        status = 'granted';
      } else if (isPermissionDenied) {
        status = 'denied';
      } else if (getZeroConfStatus.value === APP_UNKNOWN) {
        status = 'prompt';
      }
    } else if (Platform.is.android) {
      status = 'granted'; // Android does no not require this permission.
    }
    return status;
  });

  async function requestPermissions() {
    startDiscovery();
    if (checkPermissions.value === 'denied') {
      await openSettings();
    }
    return checkPermissions.value;
  }
  // IOS does not play well with their required Local Network permission. Meaning they offer no way to check or request the permission.
  // The biggest issue I have noticed so far is the "ready" state being fired when the permission isn't granted.... thus this work around
  // With our Zeroconf lib we can infer if its denied or accepted by looking for a set of distinct status
  //  ["loading", "cancelled","ready","waiting -65570: PolicyDenied"]
  // These 3 statuses in order indicate that the user has denied the permission
  function checkIfDenied() {
    // The expected array we will have when the permission is denied....
    // const deniedPermissionSignature = [
    //   'loading',
    //   'cancelled',
    //   'ready',
    //   'waiting -65570: PolicyDenied',
    // ];

    return (
      // deniedPermissionSignature.toString() === statusCache.value.toString() ||
      getZeroConfStatus.value === 'waiting -65570: PolicyDenied'
    );
  }

  function checkIfLoading() {
    const loadingPermissionSignatures = [
      ['loading', 'cancelled', 'ready'],
      ['loading', 'ready'],
      ['loading'],
    ];
    const isPermissionLoading = loadingPermissionSignatures.find(
      (signature) => {
        return signature.toString() === statusCache.value.toString();
      },
    );
    return isPermissionLoading != undefined;
  }

  function checkIfGranted() {
    const isPermissionLoading = checkIfLoading();
    if (isPermissionLoading) {
      return false;
    } else {
      return Object.values(SUCCESS_STATUSES).includes(getZeroConfStatus.value);
    }
  }

  async function startDiscovery(reset = true) {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    try {
      // reset the status cache...
      if (reset)
        statusCache.value = [WAIT_STATUSES.LOADING as ZeroConfWatchAction];
      else statusCache.value.push(WAIT_STATUSES.LOADING as ZeroConfWatchAction);
      // Specify what services we want to discover
      const watchRequest: ZeroConfWatchRequest = {
        type: '_lumin-https._tcp.',
        domain: 'local.',
      };

      if (Capacitor.getPlatform() === 'ios') {
        await ZeroConf.unwatch(watchRequest);
      }
      // Start watching for services advertizing the above config
      ZeroConf.watch(watchRequest, (result) => {
        if (!result) return;
        console.info('ZeroConf service update', result);
        const action = result?.action;
        const service = result?.service;
        statusCache.value.push(action);
        switch (action) {
          case ZEROCONF_STATUSES.RESUME_FAILED:
            startDiscovery();
            break;
          case ZEROCONF_STATUSES.ADDED:
            if (service.ipv4Addresses.length) processService(service);
            break;
          case ZEROCONF_STATUSES.RESOLVED:
            processService(service);

            if (Capacitor.getPlatform() == 'android') {
              sendServiceMapToNativePlugins();
            }
            break;
          case ZEROCONF_STATUSES.REMOVED:
            delete services.value[service.name];
            break;
          default:
        }
      });
    } catch (e) {
      console.error('Zeroconf: watch error', e);
    }
  }

  async function openSettings() {
    await settings.openSettings();
    App.addListener('resume', () => {
      startDiscovery();
    });
  }

  function getServiceBySerial(serial: string) {
    const hub = servicesArray.value.find(
      (service) =>
        service?.name.toLowerCase() === 'lumin-' + serial.toLowerCase(),
    );
    return hub;
  }

  function getServiceBySystemId(systemId: string): undefined | ZeroConfService {
    const system = systemStore.getSystemById(systemId);

    if (system) {
      const service = servicesArray.value.find(
        (service) => service?.txtRecord?.hub_id === system.hub_id,
      );

      if (service) {
        return service;
      }
    }
  }

  App.addListener('resume', () => {
    startDiscovery();
  });
  // Watch for network changes and restart the discovery service if the network status changed and its on wifi
  watch(
    [network.isWiFi, auth?.provider?.user],
    () => {
      if (network.isWiFi.value && auth?.provider?.user?.value) {
        startDiscovery();
      }
    },
    { immediate: true },
  );

  function createFallbackMockService(serial: string) {
    const service = {
      domain: 'local.',
      type: '_lumin-https._tcp.',
      name: 'lumin-' + serial,
      port: 443,
      hostname: 'lumin-' + serial + '._lumin-https._tcp.',
      ipv4Addresses: ['192.168.11.1'],
      ipv6Addresses: [],
      txtRecord: {
        hub_id: 'fake_hub_id',
        hardware: 'lsp',
        protocol: 'https',
        mock: 'mock-hotspot',
      },
    };

    processService(service);
    if (Capacitor.getPlatform() == 'android') {
      sendServiceMapToNativePlugins();
    }
  }

  function clearMockService() {
    let serviceRemoved = null;
    for (const x in services.value) {
      const service = services.value[x];
      if (service.txtRecord.hasOwnProperty('mock')) {
        serviceRemoved = service;
        delete services.value[service.name];
      }
    }
    if (Capacitor.getPlatform() == 'android') {
      sendServiceMapToNativePlugins();
    }
    return serviceRemoved;
  }

  onMounted(() => {
    Object.values(cachedServices.value).forEach(async (service) => {
      const hubStore = useHubStore();
      try {
        const hubInfo = await hubStore.fetchHubInfo(service);
        if (hubInfo) {
          processService(service);
        }
      } catch (e) {
        delete cachedServices.value[service.name];
        console.error('Could not ping cached hub', e);
      }
    });
  });

  return {
    services,
    servicesArray,
    checkPermissions,
    requestPermissions,
    startDiscovery,
    openSettings,
    createFallbackMockService,
    getServiceBySerial,
    getServiceBySystemId,
    clearMockService,
  };
}

// get the API's based url returning either a local or cloud base url
export function getBaseURL(hubService: ZeroConfService | undefined) {
  const hubStore = useHubStore();
  const { getEnvironment } = useEnvironments();
  if (hubService) {
    return hubStore.getHubUrl(hubService).url + '/';
  }
  return getEnvironment.value.api.VITE_API_BASE_URL;
}

export type { ZeroConfService as ZeroConfService };

function processService(service: ZeroConfService) {
  // If the port is not set or its 0 lets default it to 443
  if (!service.port) {
    service.port = 443;
  }
  if (!service?.txtRecord?.protocol) {
    const portMap = {
      80: 'http',
      443: 'https',
    };
    const protocol = portMap[service.port as keyof typeof portMap];

    service.txtRecord.protocol = protocol;
  }
  services.value[service.name] = service;
  cachedServices.value[service.name] = service;
}

function sendServiceMapToNativePlugins() {
  const serviceList: ServiceEntry[] = [];
  servicesArray.value.forEach((service) => {
    if (
      service.name &&
      service.ipv4Addresses !== undefined &&
      service.ipv4Addresses.length
    ) {
      serviceList.push({
        name: service.name + '.' + service.domain.split('.')[0],
        ipv4Address: service.ipv4Addresses[0],
      });
    }
  });

  CustomTLSValidator.validateTLS({
    ServiceList: serviceList,
  });

  CapacitorWebsocket.updateHostNames({
    ServiceList: serviceList,
  });
}

import { LocalStorage, Dark } from 'quasar';

import { StatusBar, Style } from '@capacitor/status-bar/dist/esm';
import { Capacitor } from '@capacitor/core';
import { useTheme } from '@luminsmart/building-blocks';
type Theme = 'light' | 'dark' | 'system' | string;

export function setupTheme() {
  const darkMode = getTheme();
  if (typeof darkMode !== 'string') {
    setTheme('system');
  } else {
    setTheme(darkMode);
  }
}

export function getTheme() {
  const buildingBlocksTheme = useTheme();
  const theme = buildingBlocksTheme.getTheme();
  return theme.mode;
}

export function isDarkTheme() {
  const theme = getTheme();
  if (!theme) {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      return 'dark';
    } else {
      return 'light';
    }
  } else {
    return theme;
  }
}

export function setTheme(theme: Theme) {
  /*
   * NOTE: Force Dark mode until we finish light mode
   */
  theme = 'dark';
  theme = theme.toLowerCase();
  // if the user has system set for their theme we don't want to save it to local storage and we want to figure out what their system is set to
  if (theme === 'system') {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      // dark mode
      theme = 'dark';
    } else {
      theme = 'light';
    }
    LocalStorage.remove('theme');
  } else {
    LocalStorage.set('theme', theme);
  }
  Dark.set(theme === 'dark');
  setStatusBar();
}

export function getBranding() {
  // Get the root element
  const r = document.querySelector('body');
  if (r) {
    const rs = getComputedStyle(r);
    const brand = {
      '--q-color-primary': rs.getPropertyValue('--primary-bg'),
      '--q-color-secondary': rs.getPropertyValue('--tertiary-bg'),
      '--q-color-positive': rs.getPropertyValue('--success-bg'),
      '--q-color-accent': rs.getPropertyValue('--tertiary-bg'),
      '--q-color-negative': rs.getPropertyValue('--error-bg'),
      '--q-color-info': rs.getPropertyValue('--info-bg'),
      '--q-color-warning': rs.getPropertyValue('--warning-bg'),
      '--q-color-dark': rs.getPropertyValue('--dark-grey-bg'),
      // '--q-color-main-bg': rs.getPropertyValue('--background-bg'),
    };

    return JSON.stringify({
      company: import.meta.env.VITE_COMPANY_NAME,
      brand,
    });
  }
}

// Set the status bar
export function setStatusBar(theme = getTheme()) {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    if (theme === 'dark') {
      StatusBar.setStyle({ style: Style.Dark });
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setBackgroundColor({ color: '#17171A' });
      }
    } else {
      StatusBar.setStyle({ style: Style.Light });
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setBackgroundColor({ color: '#FFFFFF' });
      }
    }
  }
}
